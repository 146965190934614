import React, { createContext, useState, useContext } from 'react'

const IsPublishToCustomDomainContext = createContext()

export const IsPublishToCustomDomainProvider = ({ children }) => {
  const [isPublishToCustomDomain, setIsPublishToCustomDomain] = useState(false)

  return (
    <IsPublishToCustomDomainContext.Provider value={{ isPublishToCustomDomain, setIsPublishToCustomDomain }}>
      {children}
    </IsPublishToCustomDomainContext.Provider>
  )
}

export const useIsPublishToCustomDomain = () => {
  const context = useContext(IsPublishToCustomDomainContext)
  return context
}
