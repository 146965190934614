import { Modal, Spinner } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import { listFiles } from '../../services/update-site'
import { ErrorBox } from '../ErrorBox/ErrorBox'
import CustomModal from 'components/CustomModal/CustomModal'

import styles from './FileListModal.module.css'

const FileListModal = ({ onHide, show, domain, onFileSelected, filesList, title, subtitle }) => {
  const [hasErrored, setHasErrored] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [fileList, setFileList] = useState([])

  const fetchFileList = () => {
    if (filesList) {
      setFileList(filesList)
      setIsLoading(false)
    } else {
      listFiles({ domain: domain.subdomain })
        .then(({ data: { fileList } }) => {
          setFileList(fileList?.map((f) => f.Key))
          setIsLoading(false)
        })
        .catch(() => {
          setIsLoading(false)
          setHasErrored(true)
        })
    }
  }

  useEffect(() => {
    if (show) {
      setIsLoading(true)
      fetchFileList()
    }
  }, [show])

  const onClose = () => {
    setHasErrored(false)
    setFileList([])
    onHide()
  }

  return (
    <CustomModal
      show={show}
      onHide={onClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      enforceFocus={false}
      mobileCloseButton={false}
    >
      <div className="pt-4 pb-4">
        {isLoading && <Spinner className="ms-2" animation="border" variant="primary" />}
        {fileList.length > 0 && (
          <div>
            <div className="mb-4 ms-4">
              <h4 data-testid="FileListModal-h4-multiple-index">{title || 'Select file to edit'}</h4>
              {subtitle && <div className="grey font-16">{subtitle}</div>}
            </div>
            <div>
              {fileList.map((file, i) => (
                <div className={i % 2 === 0 ? styles.even : styles.odd} onClick={() => onFileSelected(file)} key={i}>
                  {domain?.subdomain ? file.replace(`${domain.subdomain}/`, '') : file}
                </div>
              ))}
            </div>
          </div>
        )}
        {hasErrored && <ErrorBox onClick={onClose} />}
      </div>
    </CustomModal>
  )
}

export default FileListModal
