import { useState, useEffect } from 'react'
import Accordion from 'react-bootstrap/Accordion'
import { Spinner, Button } from 'react-bootstrap'

import styles from '../CustomDomainModal.module.css'
import CTAButton from 'components/CTAButton/CTAButton'
import Timer from './Timer'
import { autoConnectDomainFinish, autoConnectDomainAdd } from '../../../services/custom-domain'
import { domainValidateErrorMessage, domainErrorMessage } from '../../CustomDomainTable'
import { SUCCESS, VALIDATE_RECORDS } from '../constants/strings'
import DNSTable from './DNSTable'
import { API_ROOT } from 'constants'
import api from 'utils/api'
import { useIsPublishToCustomDomain } from '../context/IsPublishToCustomDomain'

const Success = ({ formik, onPrevious, updateDomains, submit, onFail, onSelectEntri, refreshProfile }) => {
  const [loading, setLoading] = useState(false)
  const { isPublishToCustomDomain, setIsPublishToCustomDomain } = useIsPublishToCustomDomain()
  const createdTime = localStorage.getItem('start_time')
  const validate = localStorage.getItem('validate')
  const retry = localStorage.getItem('retry')
  const viewRecords = localStorage.getItem('view_records')
  const { values } = formik
  const dnsData = values.dnsRecords.data
  const domain = values.dnsAddress.domain
  const subdomain = values.dnsAddress.subdomain

  const updateSitePublishToCustomDomain = async (site) => {
    let splitSubdomain = site.publishToCustomDomain?.split('.')
    let subdomain = splitSubdomain[0]
    let tld = `.${site.domain}`

    const formData = new FormData()
    formData.append('newDomain', `${subdomain}${tld}`)
    formData.append('publishToCustomDomain', site.publishToCustomDomain)

    setIsPublishToCustomDomain(true)
    return await api.post(`${API_ROOT}/v3/site/update?domain=${site.publishToCustomDomain}`, formData)
  }

  const handleRevalidate = async () => {
    setLoading(true)
    try {
      if (!!domain) {
        const {
          data: { domains, success }
        } = await autoConnectDomainFinish({ domain })
        if (success) {
          const publishSite = domains.find((d) => d.publishToCustomDomain)
          if (publishSite) await updateSitePublishToCustomDomain(publishSite)

          updateDomains(domains)
          formik.setFieldValue(`isSetUpComplete`, true)
          localStorage.setItem('active_custom_domain', `.${publishSite}`)
          localStorage.setItem('active_custom_subdomain', subdomain ? subdomain : 'www')
          refreshProfile()
        } else if (!success && !!validate) {
          formik.setFieldValue(`validationFailed`, true)
        } else {
          onFail(domainValidateErrorMessage)
        }
      }
      setLoading(false)
    } catch (error) {
      if (!!validate) {
        formik.setFieldValue(`validationFailed`, true)
        setLoading(false)
      } else {
        setLoading(false)
        onFail(domainValidateErrorMessage)
      }
    }
  }

  const getData = () => {
    let data = null
    if (!!retry) {
      data = JSON.parse(retry)
      formik.setFieldValue('validationFailed', true)
    } else if (!!viewRecords) {
      data = JSON.parse(viewRecords)
    }
    if (data) {
      const { domain, subdomain } = data
      formik.setFieldValue('dnsAddress.domain', domain)
      formik.setFieldValue('dnsRecords.data', data)
      formik.setFieldValue('dnsAddress.subdomain', subdomain)
    }
  }

  useEffect(() => {
    getData()
  }, [viewRecords, retry])

  return (
    <div className={styles.container}>
      {!values.isSetUpComplete ? (
        !!values.validationFailed ? (
          <ValidationFailed
            loading={loading}
            validate={validate}
            onValidate={handleRevalidate}
            values={values}
            onSelectEntri={onSelectEntri}
            onFail={onFail}
          />
        ) : (
          <>
            <div className={styles.subHeading}>
              <div className={styles.title}>{SUCCESS.SUBHEADING}</div>
              <div className={styles.subTitle}>{SUCCESS.DESCRIPTION}</div>
              <Timer creationTime={createdTime} formik={formik} />
              {!!viewRecords && (
                <Accordion className={styles.accordion} flush>
                  <Accordion.Item eventKey={1}>
                    <Accordion.Header>
                      <span className="font-16">Show DNS records</span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className={styles.recordsCon}>
                        <DNSTable data={dnsData} domain={domain} />
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              )}
            </div>
            <div className={`${styles.btnContainer} ${!!validate && styles.right}`}>
              {!!!validate && (
                <Button
                  variant="outline-primary"
                  onClick={onPrevious}
                  size="small"
                  showLeftArrow={true}
                  showArrow={false}
                  leftArrowClass={styles.backArrow}
                  disabled={true}
                >
                  Back
                </Button>
              )}
              <Button
                onClick={handleRevalidate}
                size="small"
                disabled={!values.expired}
                className={styles.forwardButton}
                loading={loading}
              >
                Validate
              </Button>
            </div>
          </>
        )
      ) : (
        <div className={styles.subHeading}>
          <div className={styles.title}>{SUCCESS.COMPLETE_TITLE}</div>
          <div className={styles.subTitle}>
            {isPublishToCustomDomain ? SUCCESS.COMPLETE_DESC_PUBLISH_TO_CUSTOM_DOMAIN : SUCCESS.COMPLETE_DESC}
          </div>
          <div className={styles.btnContainer}>
            <Button
              size="small"
              variant="outline-primary"
              showLeftArrow={true}
              showArrow={false}
              leftArrowClass={styles.backArrow}
              onClick={onPrevious}
              disabled={true}
            >
              Back
            </Button>
            <Button onClick={submit} size="small" className={styles.forwardButton}>
              {isPublishToCustomDomain ? 'Upload More' : 'Upload'}
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default Success

const ValidationFailed = ({ validate, loading, onValidate, values, onSelectEntri, onFail }) => {
  const [loadingEntri, setLoadingEntri] = useState(false)
  const dnsData = values.dnsRecords.data
  const isOwner = !!(values.dnsAddress.selected === 'yes')
  const domain_value = values.dnsAddress.domain

  const handleRetryEntri = async () => {
    const payload = {
      domain: domain_value,
      owner: isOwner,
      replaceWWW: dnsData?.isRootDomain
    }
    setLoadingEntri(true)
    try {
      const {
        data: { domain, token, cert, cfName }
      } = await autoConnectDomainAdd(payload)
      onSelectEntri(domain, token, cert, cfName, dnsData?.isRootDomain)
      setLoadingEntri(false)
    } catch (error) {
      setLoadingEntri(false)
      onFail(domainErrorMessage)
    }
  }

  return (
    <>
      {loading ? (
        <>
          <div className={styles.subHeading}>
            <div className={styles.title}>{VALIDATE_RECORDS.UPLOADING}</div>
            <div className={styles.subTitle}>{VALIDATE_RECORDS.UPLOADING_DESC}</div>
          </div>

          <div className={`${styles.btnContainer} ${styles.center}`}>
            <Spinner animation="border" />
          </div>
        </>
      ) : (
        <>
          <div className={styles.subHeading}>
            <div className={styles.title}>{VALIDATE_RECORDS.FAILED}</div>
            <div className={styles.subTitle}>{VALIDATE_RECORDS.DESCRIPTION}</div>
          </div>
          <div className={styles.recordsCon}>
            <DNSTable data={dnsData} domain={domain_value} />
          </div>
          <div className={styles.retryEntri}>
            <Button onClick={handleRetryEntri} size="sm" variant="outline-secondary" disabled={loading}>
              {loadingEntri ? <Spinner animation="border" size="sm" /> : 'Retry adding DNS records automatically'}
            </Button>
          </div>
          <div className={`${styles.btnContainer} ${styles.right}`}>
            <Button className="w-100" onClick={onValidate} variant="primary" disabled={loadingEntri}>
              Re-validate
            </Button>
          </div>
        </>
      )}
    </>
  )
}
