const ADD_DOMAIN = {
  SUBHEADING: `Connect a custom domain`,
  DESCRIPTION: `Enter a custom domain/subdomain to connect.`
}

const DNS_SETTINGS = {
  SUBHEADING: `Do you own the domain?`,
  DESCRIPTION: `Or can instruct someone to make the DNS changes (e.g. a developer)?`
}

const CONN_PATH = {
  SUBHEADING: `Do you want to connect only a subdomain?`,
  DESCRIPTION: `You can connect only a subdomain or your entire domain.`,
  PURCHASE_TITLE: `Purchase a Domain`,
  PURCHASE_DESC: `Search and find your ideal domain and we'll automatically connect it for you.`,
  PURCHASE_DESC_2: `For now, we’re not able to offer this service (coming soon), but we can recommend Namecheap as a trusted
            service. Use the search bar above to find your perfect domain. Return here once you’ve made a purchase.`
}

const WIZARD = {
  SUBHEADING: `How would you like to add DNS records?`
}

const DNS_RECORDS = {
  SUBHEADING: `DNS Records`,
  DESCRIPTION: `Please add or replace the following DNS records into your Advanced DNS Settings with your current domain
    name provider.`
}

const SUCCESS = {
  SUBHEADING: `We need to validate your setup`,
  DESCRIPTION: `Please wait for 15 minutes for the DNS records to fully propagate and then click Validate below.`,
  COMPLETE_TITLE: `Custom Domain Setup Complete`,
  COMPLETE_DESC: `Congratulations! Your Domain Setup is complete and will be ready to use in approximately 5–10 minutes.`,
  COMPLETE_DESC_PUBLISH_TO_CUSTOM_DOMAIN: `Congratulations! Your Domain Setup is complete and your site will be live in approximately 5–10 minutes.`
}

const VALIDATE_RECORDS = {
  SUBHEADING: `We're about to validate your records`,
  DESCRIPTION: `Please ensure that the following DNS records have been correctly added:`,
  UPLOADING: `Validating DNS records`,
  UPLOADING_DESC: `Please wait while we validate your DNS records`,
  FAILED: `Validation failed`
}

export { ADD_DOMAIN, DNS_SETTINGS, CONN_PATH, WIZARD, DNS_RECORDS, SUCCESS, VALIDATE_RECORDS }
